<template>
    <div>
      <div>
        <MenuThird :listName="'shopTotal'"></MenuThird>
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </template>
  
  <script>
  import MenuThird from "@/components/menu/menu-third.vue";
  export default {
    components: { MenuThird },
  
    directives: {},
  
    data() {
      return {};
    },
  
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style lang="less" scoped></style>
  